<template>
<div class="loading-screen" v-bind:class="classes" v-bind:style="{ backgroundColor: bc }" v-show="loading">
  <div style="text-align: center">
    <div class="loading-circle"></div>
    <p class="loading-text">{{ text === 'Loading' && textModal ? textModal : text }}</p>
  </div>
</div></template>

<script>
export default {
  name: 'LoadingPlugin',
  props: {
    textModal: String,
  },
  data() {
    return {
      text: 'Loading',
      dark: false,
      classes: null,
      loading: false,
      background: null,
      customLoader: null,
    };
  },
  computed: {
    bc() {
      return (
        this.background ||
        (this.dark ? 'rgba(0,0,0,0.8)' : 'rgba(255,255,255,0.8)')
      );
    },
  },
};
</script>

<style scoped>
.loading-screen {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
  width: 100vw;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 9999;
  flex-direction: column;
  user-select: none;
}
.loading-circle {
  margin: auto;
  width: 50px;
  height: 50px;
  border-radius: 100%;
  border: 2px solid transparent;
  border-left-color: #ababab;
  animation: circleanimation 0.45s linear infinite;
}
.loading-text {
  margin-top: 15px;
  color: #808080;
  font-size: 12px;
  text-align: center;
}
@keyframes circleanimation {
  from {
    transform: rotateZ(0deg);
  }
  to {
    transform: rotateZ(360deg);
  }
}
</style>
