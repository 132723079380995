<template>
<div>
    <TabNav :tabs="tabsViewRoles" :selected="selected" @selected="setSelected">
        <TabComponent :isSelected="selected === 'Gravações'">
            <Records></Records>
        </TabComponent>
        <TabComponent :isSelected="selected === 'Relatórios'">
            <Reports></Reports>
        </TabComponent>
        <TabComponent :isSelected="selected === 'Admin'">
            <Admin></Admin>
        </TabComponent>
        <TabComponent :isSelected="selected === 'Bloqueio de horario'">
          <BlockHours></BlockHours>
        </TabComponent>
        <TabComponent :isSelected="selected === 'Pasta'">
          <Folder></Folder>
        </TabComponent>
        <TabComponent :isSelected="selected === 'Chave de Busca'">
          <KeyFolder></KeyFolder>
        </TabComponent>
    </TabNav>
</div>
</template>
<script>
import TabNav from '../../components/tabs/TabNav.vue';
import TabComponent from '../../components/tabs/TabComponent.vue';
import Admin from './Admin.vue';
import Records from './Records.vue';
import Reports from './Reports.vue';
import BlockHours from './BlockHours.vue';
import Folder from './Folder.vue';
import KeyFolder from './KeyFolder.vue';
import axios from 'axios';

export default {
  components: {
    TabNav,
    TabComponent,
    Records,
    Reports,
    Admin,
    BlockHours,
    Folder,
    KeyFolder,
  },
  data() {
    return {
      selected: '',
      roleUser: '',
      tabsViewRoles: []
    };
  },
  beforeCreate() {
    let course_idS = localStorage.getItem('course_id');
    let user_idS = localStorage.getItem('user_id');

    this.$loading(true);
    axios.post(`${process.env.VUE_APP_ROOT_API}/blackboard/users`, {
        course_id: course_idS,
        user_id: user_idS,
      }).then((resposta) => {
        console.log('gerando dados');
        console.log(resposta.data.roles[0].role);
        this.roleUser = resposta.data.roles[0].role

        if(this.roleUser == 'master') {
          this.tabsViewRoles = ['Bloqueio de horario','Gravações','Relatórios','Pasta','Admin', 'Chave de Busca'];
          this.selected = 'Bloqueio de horario'
        } 

        if(this.roleUser == 'report') {
          this.tabsViewRoles = ['Relatórios'];
          this.selected = 'Relatórios'
        } 

        if(this.roleUser == 'folder') {
          this.tabsViewRoles = ['Pasta', 'Chave de Busca'];
          this.selected = 'Pasta'
        }

        if(this.roleUser == 'record') {
          this.tabsViewRoles = ['Gravações'];
          this.selected = 'Gravações'
        } 

        if(this.roleUser == 'room') {
          this.tabsViewRoles = ['Bloqueio de horario'];
          this.selected = 'Bloqueio de horario'
        } 

        console.log(resposta.data.user.id);
      }).catch((error) => {
        console.error(error);
      }).finally(() => {
        this.$loading(false);
      });
  },
  methods: {
    setSelected(tab) {
      this.selected = tab;
    },
  },
};
</script>

<style scoped>
.tab {
  width: 100%;
}
</style>
