<template>
<div class="modal" ref="modal" tabindex="-1">
  <div class="modal-dialog" :class="[{ loaded: !loading }, `modal-${size}`]">
    <div class="modal-content">
      <div class="modal-header">
        <div class="modal-title">{{ title }}</div>
        <button class="close" @click="close" type="button" v-if="!removeCloseButton">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <slot></slot>
      </div>
      <div class="modal-footer" v-if="!noFooter">
        <div v-if="auxButtonShow">
          <button class="btn btn-thin flex-fill" @click='$emit("auxButtonAction")' v-if="auxButtonShow">
            <span aria-hidden="true">{{ auxButtonLabel }}</span>
          </button>
        </div>
        <div class="d-flex flex-grow-1 justify-content-end">
          <button class="btn btn-danger" @click="cancel" type="button" v-if="showCancelButton">{{ closeButtonLabel }}</button>
          <button class="btn" id="buttonColor" :class="[processButtonClass]" :disabled="disableProcessButton" @click='$emit("process")' type="button" v-if="processButtonLabel">{{ processButtonLabel }}</button>
        </div>
      </div>
    </div>
  </div>
</div>
</template>

<script>
export default {
  name: 'ModalMolecule',
  props: {
    loading: Boolean,
    title: String,
    closeButtonLabel: String,
    processButtonLabel: String,
    processButtonClass: {
      type: String,
      default: 'btn-primary',
    },
    noFooter: {
      type: Boolean,
      default: false,
    },
    showCancelButton: {
      type: Boolean,
      default: true,
    },
    disableProcessButton: {
      type: Boolean,
      default: false,
    },
    size: {
      type: String,
      default: 'lg',
    },
    removeCloseButton: Boolean,
    auxButtonLabel: {
      type: String,
      default: '',
    },
    auxButtonShow: {
      type: Boolean,
      default: false,
    },
    auxButtonAction: Function,
    cancelAction: Function,
  },
  data() {
    return {
      modal: null,
      modalElement: null,
    };
  },
  mounted() {
    this.modalElement = document
      .querySelector('body')
      .appendChild(this.$refs.modal);
    this.modal = new window.bootstrap.Modal(this.modalElement);
    this.modalElement.addEventListener('hidden.bs.modal', () => {
      this.$emit('closed');
    });
    this.modalElement.addEventListener('show.bs.modal', () => {
      this.$emit('opened');
    });
  },
  destroyed() {
    this.modal.dispose();
    this.modalElement.remove();
  },
  methods: {
    close() {
      this.modal.hide();
    },
    cancel() {
      if (this.cancelAction instanceof Function) {
        this.cancelAction();
        return;
      }
      this.close();
    },
    open() {
      this.modal.show();
    },
  },
};
</script>

<style scoped>
.modal {
  z-index: 5555;
}
.modal-dialog {
  margin-top: 5rem;
}
.modal-dialog.loaded .modal-content {
  opacity: 1;
}
.modal-dialog.loaded .modal-loading {
  opacity: 0;
}
.modal-footer {
  justify-content: space-between;
}
.modal-footer >* {
  margin: 0;
}
.modal-footer button {
  margin: 0.25rem;
}
@media screen and (min-width: 576px) {
  .modal-sm {
    max-width: 420px;
  }
}
</style>
