<template>
<div class="admin w-100">
  <div class="admin-container container">
    <div class="title-admin d-flex flex-row justify-content-center">
      <h3 class="py-3">Administração</h3>
    </div>
    <div class="d-flex flex-column justify-content-start">
      <div class="mb-3 d-flex flex-row justify-content-center">
        <button class="btn btn-primary mt-2" @click="openCreateUserModal">Criar novo usuário</button>
      </div>
      <div class="table-list-users d-flex flex-row justify-content-center">
          <table class="mb-5">
            <thead class="table-titles">
              <tr>
                <th>User name</th>
                <th>Permissão</th>
                <th>Ações</th>
              </tr>
            </thead>
            <tbody v-for="user in users" :key="user.id">
              <tr>
                <td>{{user.blackboard.userName}} </td>
                <td> {{user.role.role}} </td>
                <td>
                  <button class="btn bi bi-trash-fill" type="button" data-bs-toggle="tooltip" data-bs-placement="bottom" title="Excluir" @click="deleteUser(user.id)"></button>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
    </div>
  </div>
  <create-user-modal ref="createUserModal" @refresh="loadUsers"></create-user-modal>
</div>
</template>

<script>
import CreateUserModal from '../../components/modals/CreateUserModal.vue';
import axios from 'axios';
import alert from '../../plugins/alert'

export default {
  components: { CreateUserModal },
  data() {
    return {
      userIdLocal: '',
      selectButton: true,
      users: {
        blackboard: {
          name: {
            given: '',
          },
        id: '',
        role: {
          role: '',
        },
        // user_id: '',
        }
      },
    };
  },
  beforeCreate() {
    // this.$loading(true);
    // localStorage.setItem('user_id', user_id);
    // this.userIdLocal = localStorage.getItem('user_id');
    // this.$loading(true);
    let id = localStorage.getItem('id');
    this.$loading(true);
    axios.post((`${process.env.VUE_APP_ROOT_API}/admin/users`), {
        user_id: id,
        // offset: '1',
      }).then((resposta) => {
        console.log(resposta ,'carrega usuarios');
        this.users = resposta.data.admins;
        console.log(this.users, 'users');
      }).catch((error) => {
        console.log(error ,'erro');
      }).finally(() => {
        this.$loading(false);
      });
  },
  methods: {
    loadUsers(){
    let id = localStorage.getItem('id');
    this.$loading(true);
    axios.post((`${process.env.VUE_APP_ROOT_API}/admin/users`), {
        user_id: id,
        // offset: '1',
      }).then((resposta) => {
        console.log(resposta ,'carrega usuarios');
        this.users = resposta.data.admins;
        console.log(this.users, 'users');
      }).catch((error) => {
        console.log(error ,'erro');
      }).finally(() => {
        this.$loading(false);
      });
    },
    usersSelected() {
      this.selectButton = false;
    },
    optionsSelected() {
      this.selectButton = true;
    },
    openCreateUserModal() {
      this.$refs.createUserModal.$refs.modal.open();
    },
    deleteUser(userId) {
      let id = localStorage.getItem('id');

      // let setUserId = '_793760_1';
      let givenUserId = userId;

      console.log('MEU ID : ' + typeof(id));
      console.log('ID QUE VAI SER DELETADO : ' + userId);
      console.log(`${process.env.VUE_APP_ROOT_API}/admin/user?user_id=${id}&admin_id=${userId}`);
      this.$loading(true);
      axios.delete(`${process.env.VUE_APP_ROOT_API}/admin/user?user_id=${id}&admin_id=${userId}`, {
      }).then((resposta) => {
        console.log(resposta ,'sucesso');
        alert.fireAlert('Usuário excluído com sucesso!', {
            classes: 'alert-success bi bi-check-circle',
            styles:
              'background-color: #d4edda; border-color: #c3e6cb; color: #155724;',
          });
      }).catch((error) => {
        alert.fireAlert(
            `Não foi possível excluir o usuário.`,
            {
              classes: 'alert-danger bi bi-exclamation-circle',
              styles:
                'background-color: #f8d7da; border-color: #F5C6CB; color: #721C24;',
              tag: 'httpAlert',
            }
          );
        console.log(error ,'erro');
      }).finally(() => {
        this.$loading(false);
        this.loadUsers();
      });
    },
  },
};
</script>

<style scoped>
.admin {
  background-color: lightblue;
}

.info-folder {
  border: 1px solid lightcoral;
  color: lightcoral;
  background-color: lightyellow;
  max-width: 700px;
}

table {
  border-collapse: collapse;
  /* border-radius: 15px; */
  border-style: hidden /* hide standard table (collapsed) border */;
  box-shadow: 0 0 0 1px #666 /* this draws the table border  */;
  background-color: #fff;
  height: 40%;
  width: auto;
}
table th {
  padding: 0px 10px;
  text-align: center;
  border: 1px solid #ccc;
}
table td {
  padding: 0px 10px;
  text-align: center;
  border: 1px solid #ccc;
}

.table-titles {
  color: #FFF;
  background-color: var(--azul-tres);
}

.bi-pencil-square {
  color: blue;
}

.bi-trash-fill {
  color: red;
}
</style>
