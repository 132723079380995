import Vue from 'vue';
import VueRouter from 'vue-router';
import Painel from '../pages/Painel.vue';
import AgendarHorario from '../pages/AgendarHorario.vue';
import PartipantesPage from '../pages/ParticipantsPage.vue'

Vue.use(VueRouter);

const routes = [
  {
    path: '/',
    redirect: '/agendar'
  },
  {
    path: '/agendar',
    name: 'Agendar',
    component: AgendarHorario,
  },
  {
    path: '/painel',
    name: 'Painel',
    component: Painel,
  },
  { 
    path: '/participants/:meetingId',
    name: 'Participantes',
    component: PartipantesPage,
   },
];

const router = new VueRouter({
  routes,
  mode:'history',
});

export default router;
