<template>
<div class="folder w-100">
  <div class="folder-container container">
    <div class="admin-options">
      <div>
        <h5 class="pt-3">Aplicação a Chave de Busca:</h5>
        <div class="info-folder">
          <p class="ms-2 me-2">Utilize a Chave de Busca para vincular pastas com o nome configurado dos cursos que respeitam essa chave, assim a pasta seria configurada corretamente<br>
          </p>
        </div>
      </div>
      <form class="mt-2">
        <h6>Chave de Busca:</h6>
        <input type="text" name="Nome-da-pasta" placeholder="Digite a chave de busca" v-model="keyFolder" required>
      </form>
      <button class="btn btn-primary mb-4 mt-4" @click="SearchKeyFolder">Buscar</button>
    </div>
  </div>
</div>
</template>

<script>
import axios from 'axios';
import alert from '../../plugins/alert';
import axiosRetry from 'axios-retry';

export default {
  name: 'KeyFolder',
  data() {
    return {
      keyFolder: '',
    };
  },
  methods: {
    SearchKeyFolder() {
      let id = localStorage.getItem('user_id')
      this.$loading(true);

      axiosRetry(axios, { retries: 100 });

      axios.post((`${process.env.VUE_APP_ROOT_API}/admin/load/folders`), {
        searchString: this.keyFolder,
        user_id: id
      }).then((resposta) => {
        console.log(resposta ,'Obeteve os cursos');
        axios.post((`${process.env.VUE_APP_ROOT_API}/admin/load/folders/prepare`), {
          searchString: this.keyFolder,
          user_id: id
        }).then((resposta) => {
          console.log(resposta ,'Preparou os cursos para processar as pastas');
          const numRepeat = Math.ceil(resposta.data.itensCreated / 50);
          this.changeFolder(numRepeat);
        }).catch((error) => {
          this.$loading(false);
          alert.fireAlert(`Não foi possível encontrar a chave `, {
            classes: 'alert-danger bi bi-exclamation-circle',
            styles:
            'background-color: #f8d7da; border-color: #F5C6CB; color: #721C24;',
            tag: 'httpAlert',
          });
        })
      }).catch((error) => {
        console.log(error ,'erro na procura das pastas 1');
        alert.fireAlert(`Não foi possível encontrar palavra chave.`, {
          classes: 'alert-danger bi bi-exclamation-circle',
          styles:
            'background-color: #f8d7da; border-color: #F5C6CB; color: #721C24;',
          tag: 'httpAlert',
        });
      })
    },
    async changeFolder(numRepeat) {
      let id = localStorage.getItem('user_id')
      for(let i = 1; i <= numRepeat; i++) {
        await axios.post((`${process.env.VUE_APP_ROOT_API}/admin/load/folders/process`), {
        searchString: this.keyFolder,
        user_id: id
        }).then((resposta) => {
          console.log(resposta ,'Processando pastas pacote: ' + i);
        }).catch((error) => {
          console.log(error ,'erro na procura das pastas 3');
        })
      }
      this.$loading(false);
      alert.fireAlert(`Alteração realizada com sucesso.`, {
        classes: 'alert-success bi bi-check-circle',
          styles:
            'background-color: #d4edda; border-color: #c3e6cb; color: #155724;',
        });
    }
  },
};
</script>

<style scoped>
.folder {
  background-color: lightblue;
}

.info-folder {
  border: 1px solid lightcoral;
  color: lightcoral;
  background-color: lightyellow;
  max-width: 700px;
}

</style>
