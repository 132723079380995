<template>
<modal class="text-center" id="disclaimer-modal" @process="isChecked" :buttonColor="buttonColor" :loading="loading" close-button-label="Cancelar" process-button-label="Confirmar" processButtonClass="btn-primary" ref="modal" size="md" title="AUTORIZACAO DE USO DE IMAGEM E SOM DE VOZ">
  <div class="text-start container">
    A presente autorização abrange os usos acima indicados tanto em mídia impressa (livros, catálogos, revista, jornal, entre outros) como também em mídia eletrônica (programas de rádio, podcasts, vídeos e filmes para televisão aberta e/ou fechada, documentários para cinema ou televisão, entre outros), Internet, Intranet, blogs, Banco de Dados Informatizado Multimídia, "home vídeo" DVD ("digital vídeo disc"), suportes de computação gráfica em geral, material publicitário, institucionais, informativos e internos banners, cartazes, painéis) sem qualquer ônus ao Grupo Ser Educacional S.A., que poderá utilizá-los em todo e qualquer projeto e/ou obra de natureza educativa, sócio e cultural, voltados à divulgação do conhecimento em todo território nacional e no exterior, de maneira a resguardar o direito à intimidade e à honra, evitando situações depreciativas e/ou constrangedoras da moral.
  </div>
  <div class="check-autorize d-flex flex-row mt-3">
    <input class="ms-2 form-check-input" type="checkbox"/>
    <label class="text-start ms-2">Estou de acordo com os termos descritos acima, para ler o conteúdo completo <a href="../../assets/autorizacao.pdf" download>clique aqui</a>.</label>
  </div>
  <h5 class="erro-lgpd d-flex flex-row mt-1" style="display: none !important">É necessário aceitar os Termos de Uso e Política de Privacidade</h5>
</modal>
</template>

<script>
import Modal from '../../ui/Modal.vue';

export default {
  name: 'DisclaimerModal',
  components: {
    Modal,
  },
  data() {
    return {
      loading: false,
      buttonColor: true,
    };
  },
  methods: {
    isChecked() {
      if(document.getElementsByClassName('ms-2 form-check-input')[0].checked) {
        this.$refs.modal.close();
        this.$emit('checked');
      }
    },
  }
};
</script>

<style scoped>

.form-check-input[type=checkbox] {
    border-radius: 0.25em;
    width: 25px;
}
.modal-header {
  background-color: #2D8CFF;
}
.close {
  display: none;
}
.modal-title {
  font-weight: bold;
  color: #FFF;
  margin-left: 2rem;
}
.modal-footer {
  background-color: #2D8CFF;
}

.erro-lgpd {
  font-size: 12px;
  color: red;
  margin-left: 10px;
}

</style>