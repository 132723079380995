<template>
<div class="records w-100 d-flex flex-column">
  <h4 class="d-flex flex-row justify-content-center mt-4">APAGAR GRAVAÇÕES</h4>
  <div class="d-flex flex-row mb-2 mt-4 justify-content-center">
    <div class="d-flex flex-column">
      <div class="d-flex flex-row mb-2">
        <span class="mt-1">Data inicial: </span>
        <input class="ms-2" id="dataInicial" type="date"/>
        <span class="mt-1 ms-3">E-mail do Professor:</span>
        <input class="ms-2" id="emailProfessor" type="email"/>
      </div>
      <div class="d-flex flex-row">
        <span class="mt-1">Data final: </span>
        <input class="ms-3" id="dataFinal" type="date"/>
        <span class="mt-1 ms-3">Nome do curso:</span>
        <input class="ms-2" id="nomeCurso" type="text"/>
      </div>
      <button class="btn btn-primary w-25 justify-content-center align-self-center mt-2" type="submit" @click="gerarRecording">Buscar</button>
    </div>
  </div>
  <div class="table-reports justify-content-center d-flex flex-row mt-4">
    <table class="mb-5" v-if="this.searched">
      <thead class="table-titles">
        <tr>
          <th>Seleção <br><input type="checkbox" id="marcaAll"  @change="changeCheckBox($event)"></th>
          <th>Nome do curso</th>
          <th>Professor</th>
          <th>Email</th>
          <th>Duração</th>
          <th>Ações</th>
        </tr>
      </thead>
      <!-- eslint-disable vue/no-use-v-if-with-v-for,vue/no-confusing-v-for-v-if -->
      <tbody>
        <tr v-for="(meeting, index) in meetings" :key="index">
          <td>
            <input type="checkbox" class="checks" v-bind:id="meeting.meetingId">
          </td>
          <td>{{meeting.subject}}</td>
          <td>{{meeting.host_name}}</td>
          <td>{{meeting.host_email}}</td>
          <td>{{meeting.record_duration}}</td>
          <td>
            <button class="btn bi bi-play-fill button-watch" type="button" data-bs-toggle="tooltip" data-bs-placement="bottom" title="Assistir" @click="mostrarGravacao(meeting.play_url)"></button>
            <button class="btn bi bi-trash-fill button-delete" type="button" data-bs-toggle="tooltip" data-bs-placement="bottom" title="Excluir" @click="deleteARecord(meeting.meetingId)"></button>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
  <div class="d-flex flex-row justify-content-center mb-4">
    <button class="btn btn-danger ms-2" @click="deleteRecordingDate">Deletar</button>
  </div>
</div>
</template>

<script>
import axios from 'axios';
import alert from '../../plugins/alert'

export default {
  name: 'Records',
  data() {
    return {
      meetingId: String,
      startData: String,
      endDate: String,
      email: String,
      user_id: String,
      meetings: '',
      searched: false,
    };
  },
  methods: {
    gerarRecording() {
      let id = localStorage.getItem('id');
      let dataInicial = document.getElementById('dataInicial').value
      let dataFinal = document.getElementById('dataFinal').value
      let emailProfessor = document.getElementById('emailProfessor').value
      let nomeCurso = document.getElementById('nomeCurso').value
      let data = {}

      data = {
          start_date: dataInicial,
          end_date: dataFinal,
          user_id: id,
        }

      if(emailProfessor)
        data['email'] = emailProfessor;
      if(nomeCurso)  
        data['subject'] = nomeCurso;

      this.$loading(true);
      axios.post(`${process.env.VUE_APP_ROOT_API}/admin/recording`, data).then((resposta) => {
        console.log(resposta.data.meetings)
        this.searched = true;
        this.meetings = resposta.data.meetings
        alert.fireAlert('Gravações listadas com sucesso!', {
          classes: 'alert-success bi bi-check-circle',
          styles:
            'background-color: #d4edda; border-color: #c3e6cb; color: #155724;',
        });
      }).catch((err) => {
        console.log(err)
        alert.fireAlert(`Não foi possível listar gravações.`, {
          classes: 'alert-danger bi bi-exclamation-circle',
          styles:
            'background-color: #f8d7da; border-color: #F5C6CB; color: #721C24;',
          tag: 'httpAlert',
        });
      }).finally(() => {
        this.$loading(false);
      })
    },
    deleteRecordingDate() {
      let id = localStorage.getItem('id');
      let dataInicial = document.getElementById('dataInicial').value.replace(/\//g, '-');
      let dataFinal = document.getElementById('dataFinal').value.replace(/\//g, '-');
      console.log('Data inicial' + dataInicial)
      console.log('Data Final' + dataFinal)
      Array.from(document.querySelectorAll('.checks')).forEach(el => {
        if(el.checked) {
          this.$loading(true);
          axios.delete(`${process.env.VUE_APP_ROOT_API}/admin/recording?meetingId=${el.id}&start_date=${dataInicial}&end_date=${dataFinal}&user_id=${id}`, {
          }).then((resposta) => {
          alert.fireAlert('Gravação excluída com sucesso!', {
            classes: 'alert-success bi bi-check-circle',
            styles:
            'background-color: #d4edda; border-color: #c3e6cb; color: #155724;',
          });
            console.log(resposta)
          }).catch((err) => {
          alert.fireAlert(`Não foi possível excluir a gravação.`, {
            classes: 'alert-danger bi bi-exclamation-circle',
            styles:
              'background-color: #f8d7da; border-color: #F5C6CB; color: #721C24;',
            tag: 'httpAlert',
          });
            console.log(err)
          }).finally(() => {
            Array.from(document.querySelectorAll('.checks')).forEach(el => { 
              el.checked = false;
            })
            this.$loading(false);
          })
        }
      })
      
    },
    deleteARecord(meetingId) {
      let id = localStorage.getItem('id');
      let dataInicial = document.getElementById('dataInicial').value.replace(/\//g, '-');
      let dataFinal = document.getElementById('dataFinal').value.replace(/\//g, '-');
      this.$loading(true);
      axios.delete(`${process.env.VUE_APP_ROOT_API}/admin/recording?meetingId=${meetingId}&start_date=${dataInicial}&end_date=${dataFinal}&user_id=${id}`, {
          }).then((resposta) => {
          console.log(resposta)
          alert.fireAlert('Gravação deletada com sucesso!', {
          classes: 'alert-success bi bi-check-circle',
          styles:
            'background-color: #d4edda; border-color: #c3e6cb; color: #155724;',
          });
          }).catch((err) => {
            console.log(err);
            alert.fireAlert(
            `Não foi possível excluir a gravação.`,
            {
              classes: 'alert-danger bi bi-exclamation-circle',
              styles:
                'background-color: #f8d7da; border-color: #F5C6CB; color: #721C24;',
              tag: 'httpAlert',
            }
          );
          }).finally(() => {
            this.$loading(false);
          })
    },
    mostrarGravacao(id) {
      window.open(id)
    },
    changeCheckBox(event) {
      console.log(event.target.checked)
      if(event.target.checked == true) {
        Array.from(document.querySelectorAll('.checks')).forEach( el => {
          el.checked = true
        })
      } else {
        Array.from(document.querySelectorAll('.checks')).forEach( el => {
          el.checked = false
        })
      }
    }
  },
};
</script>

<style scoped>
.records {
  background-color: lightblue;
}

table {
  border-collapse: collapse;
  /* border-radius: 15px; */
  border-style: hidden /* hide standard table (collapsed) border */;
  box-shadow: 0 0 0 1px #666 /* this draws the table border  */;
  background-color: #fff;
  height: 40%;
  width: auto;
}
table th {
  padding: 0px 10px;
  text-align: center;
  border: 1px solid #ccc;
}
table td {
  padding: 0px 10px;
  text-align: center;
  border: 1px solid #ccc;
}

.table-titles {
  color: #FFF;
  background-color: var(--azul-tres);
}
</style>
