<template>
<nav class="navbar navbar-expand-lg navbar-light bg-light">
  <div class="container-fluid">
    <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
      <i class="bi bi-menu-down"></i>
    <router-link class="navbar-brand" to="/login"></router-link>
    </button>
    <img class="icon" src="../assets/logo.png"/>
    <div class="collapse navbar-collapse" id="navbarSupportedContent">
      <ul class="navbar-nav me-auto mb-2 mb-lg-0">
        <li class="nav-item" id="pnAdm">
          <router-link class="nav-link" to="/painel">Painel Admin</router-link>
        </li>
        <li class="nav-item">
          <router-link class="nav-link" to="/agendar">Agendamento</router-link>
        </li>
      </ul>
    </div>
  </div>
</nav>
</template>

<script>

export default {
  data() {
    return {
      token: null,
    };
  },
  mounted() {
    
  }
  
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.navbar-collapse {
  flex-grow: 0 !important;
}
.icon {
  width: auto;
  height: 70px;
}
.navbar {
  background-color: var(--verde-escuro) !important;
}
a.nav-link {
  color: var(--bege) !important;
}
i.bi.bi-cart3 {
  color: var(--bege);
  font-size: 1.563rem;
  display: flex !important;
  margin-left: 62.5rem;
}
i.bi.bi-cart3:hover {
  color: var(--verde);
}
.bi-cart3 {
  cursor: pointer;
}
.navbar-toggler-icon {
  display: none;
}

</style>
