<template>
  <div id="app">
    <div id="nav">
      <Navbar />
    </div>
    <router-view/>
  </div>
</template>

<script>
import Navbar from './components/Navbar.vue';

export default {
  components: {
    Navbar,
  }, 
};
</script>
<style>
#app {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;
  margin-top: 0px;
  --branco: #FFF;
  --preto: #000;
  --azul: #3078BE;
  --azul-dois: #2b689c;
  --azul-tres: #2D8CFF;
}

#app .fc-highlight {
  background-color: rgb(209, 243, 253);
}

#app .fc-daygrid-day-number {
  text-decoration: none;
  color: black;
  font-weight: 600;
}

#app .fc-col-header-cell {
  background-color: var(--azul-tres);
}

#app .fc-col-header-cell-cushion {
  color: var(--branco);
  text-decoration: none;
  text-transform: uppercase;
}

#app .fc-day-other {
  background-color: lightgrey;
}

#app .fc-day-sun {
  background-color: lightgrey;
}

#app .fc-col-header th:last-child {
  background-color: var(--azul-tres) !important;
} 

#app .fc-day-sun  .fc-daygrid-day-number{
  color: #989898 !important;
}

#app .fc-day-other .fc-daygrid-day-number {
  color: black;
}

#app .fc-prev-button {
  background-color: var(--azul-tres);
}

#app .fc-next-button {
  background-color: var(--azul-tres);
}

#app .fc-today-button {
  background-color: var(--azul-tres);
}

#app .fc-col-header-cell .fc-day-mon > .fc-scrollgrid-sync-inner {
  border-radius: 12px 0px 0px 0px;
}

#app .teste-css table {
  box-shadow: 0 0 0 1px #000/* this draws the table border  */;
}
</style>
