<template>
<div class="w-100 d-flex flex-column blockhour-tab">
  <div class="title-blockhour">
    <h3 class="justify-content-center d-flex mt-3">Bloqueio de horários</h3>
  </div>
  <div class="d-flex flex-row justify-content-center mb-4 mt-2">
    <span class="mt-2">Data:</span>
    <input class="ms-2" type="date" id="dataBlock"/>
    <span class="ms-2 mt-2">Tipo</span>
    <select class="ms-2 form-select-md" v-model="category">
      <option value="presencial">Cabines</option>
      <option value="home">Home-Office</option>
      <option value="pos">Graduação ao vivo</option>
    </select>
    <button type="button" class="btn btn-primary ms-2" @click="legendaBlock">Pesquisar datas</button>
  </div>
  <div class="tb-blockhour w-100 mb-5 option-presencial" v-if="this.category == 'presencial'">
    <div class="info-data">
      <span class="info-title-deactive bd-all p-1 legD"></span>
      <span class="bd-all p-1">Sala e Horários bloqueados.</span>
    </div>
    <table class="container">
      <tbody>
        <tr class="table-titles">
          <th class="th-first">Hora/Sala</th>
          <th>1 <input class="form-check-input blockDay" id="1" value="1" type="radio" v-model="selectedDayBlock"  @change="selectDateBlock($event)"/> </th>
          <th>2 <input class="form-check-input blockDay" id="2" value="2" type="radio" v-model="selectedDayBlock"  @change="selectDateBlock($event)"/> </th>
          <th>3 <input class="form-check-input blockDay" id="3" value="3" type="radio" v-model="selectedDayBlock"  @change="selectDateBlock($event)"/> </th>
          <th>4 <input class="form-check-input blockDay" id="4" value="4" type="radio" v-model="selectedDayBlock"  @change="selectDateBlock($event)"/> </th>
          <th>5 <input class="form-check-input blockDay" id="5" value="5" type="radio" v-model="selectedDayBlock"  @change="selectDateBlock($event)"/> </th>
          <th>6 <input class="form-check-input blockDay" id="6" value="6" type="radio" v-model="selectedDayBlock"  @change="selectDateBlock($event)"/> </th>
          <th>7 <input class="form-check-input blockDay" id="7" value="7" type="radio" v-model="selectedDayBlock"  @change="selectDateBlock($event)"/> </th>
          <th>8 <input class="form-check-input blockDay" id="8" value="8" type="radio" v-model="selectedDayBlock"  @change="selectDateBlock($event)"/> </th>
          <th>9 <input class="form-check-input blockDay" id="9" value="9" type="radio" v-model="selectedDayBlock"  @change="selectDateBlock($event)"/> </th>
          <th>10 <input class="form-check-input blockDay" id="10" value="10" type="radio" v-model="selectedDayBlock"  @change="selectDateBlock($event)"/> </th>
          <th>11 <input class="form-check-input blockDay" id="11" value="11" type="radio" v-model="selectedDayBlock"  @change="selectDateBlock($event)"/> </th>
          <th>12 <input class="form-check-input blockDay" id="12" value="12" type="radio" v-model="selectedDayBlock"  @change="selectDateBlock($event)"/> </th>
          <th>13 <input class="form-check-input blockDay" id="13" value="13" type="radio" v-model="selectedDayBlock"  @change="selectDateBlock($event)"/> </th>
          <th class="th-last">14 <input class="form-check-input blockDay" value="14" id="14" type="radio" v-model="selectedDayBlock"  @change="selectDateBlock($event)"/> </th>
        </tr>
      </tbody>
      <tbody>
        <tr>
          <th>08:30 - 09:30</th>
          <td>
            <input class="form-check-input class1" type="radio" id="08:30:00-1" value="08:30:00 - 1" v-model="selectedBlock"  @change="selectDateAndRoom($event)"/>
          </td>
          <td>
            <input class="form-check-input class2" type="radio" id="08:30:00-2" value="08:30:00 - 2" v-model="selectedBlock"  @change="selectDateAndRoom($event)"/>
          </td>
          <td>
            <input class="form-check-input class3" type="radio" id="08:30:00-3" value="08:30:00 - 3" v-model="selectedBlock"  @change="selectDateAndRoom($event)"/>
          </td>
          <td>
            <input class="form-check-input class4" type="radio" id="08:30:00-4" value="08:30:00 - 4" v-model="selectedBlock"  @change="selectDateAndRoom($event)"/>
          </td>
          <td>
            <input class="form-check-input class5" type="radio" id="08:30:00-5" value="08:30:00 - 5" v-model="selectedBlock"  @change="selectDateAndRoom($event)"/>
          </td>
          <td>
            <input class="form-check-input class6" type="radio" id="08:30:00-6" value="08:30:00 - 6" v-model="selectedBlock"  @change="selectDateAndRoom($event)"/>
          </td>
          <td>
            <input class="form-check-input class7" type="radio" id="08:30:00-7" value="08:30:00 - 7" v-model="selectedBlock"  @change="selectDateAndRoom($event)"/>
          </td>
          <td>
            <input class="form-check-input class8" type="radio" id="08:30:00-8" value="08:30:00 - 8" v-model="selectedBlock"  @change="selectDateAndRoom($event)"/>
          </td>
          <td>
            <input class="form-check-input class9" type="radio" id="08:30:00-9" value="08:30:00 - 9" v-model="selectedBlock"  @change="selectDateAndRoom($event)"/>
          </td>
          <td>
            <input class="form-check-input class10" type="radio" id="08:30:00-10" value="08:30:00 - 10" v-model="selectedBlock"  @change="selectDateAndRoom($event)"/>
          </td>
          <td>
            <input class="form-check-input class11" type="radio" id="08:30:00-11" value="08:30:00 - 11" v-model="selectedBlock"  @change="selectDateAndRoom($event)"/>
          </td>
          <td>
            <input class="form-check-input class12" type="radio" id="08:30:00-12" value="08:30:00 - 12" v-model="selectedBlock"  @change="selectDateAndRoom($event)"/>
          </td>
          <td>
            <input class="form-check-input class13" type="radio" id="08:30:00-13" value="08:30:00 - 13" v-model="selectedBlock"  @change="selectDateAndRoom($event)"/>
          </td>
          <td>
            <input class="form-check-input class14" type="radio" id="08:30:00-14" value="08:30:00 - 14" v-model="selectedBlock"  @change="selectDateAndRoom($event)"/>
          </td>
        </tr>
        <tr>
          <th>10:00 - 11:00</th>
          <td>
            <input class="form-check-input class1" type="radio" id="10:00:00-1" value="10:00:00 - 1" v-model="selectedBlock"  @change="selectDateAndRoom($event)"/>
          </td>
          <td>
            <input class="form-check-input class2" type="radio" id="10:00:00-2" value="10:00:00 - 2" v-model="selectedBlock"  @change="selectDateAndRoom($event)"/>
          </td>
          <td>
            <input class="form-check-input class3" type="radio" id="10:00:00-3" value="10:00:00 - 3" v-model="selectedBlock"  @change="selectDateAndRoom($event)"/>
          </td>
          <td>
            <input class="form-check-input class4" type="radio" id="10:00:00-4" value="10:00:00 - 4" v-model="selectedBlock"  @change="selectDateAndRoom($event)"/>
          </td>
          <td>
            <input class="form-check-input class5" type="radio" id="10:00:00-5" value="10:00:00 - 5" v-model="selectedBlock"  @change="selectDateAndRoom($event)"/>
          </td>
          <td>
            <input class="form-check-input class6" type="radio" id="10:00:00-6" value="10:00:00 - 6" v-model="selectedBlock"  @change="selectDateAndRoom($event)"/>
          </td>
          <td>
            <input class="form-check-input class7" type="radio" id="10:00:00-7" value="10:00:00 - 7" v-model="selectedBlock"  @change="selectDateAndRoom($event)"/>
          </td>
          <td>
            <input class="form-check-input class8" type="radio" id="10:00:00-8" value="10:00:00 - 8" v-model="selectedBlock"  @change="selectDateAndRoom($event)"/>
          </td>
          <td>
            <input class="form-check-input class9" type="radio" id="10:00:00-9" value="10:00:00 - 9" v-model="selectedBlock"  @change="selectDateAndRoom($event)"/>
          </td>
          <td>
            <input class="form-check-input class10" type="radio" id="10:00:00-10" value="10:00:00 - 10" v-model="selectedBlock"  @change="selectDateAndRoom($event)"/>
          </td>
          <td>
            <input class="form-check-input class11" type="radio" id="10:00:00-11" value="10:00:00 - 11" v-model="selectedBlock"  @change="selectDateAndRoom($event)"/>
          </td>
          <td>
            <input class="form-check-input class12" type="radio" id="10:00:00-12" value="10:00:00 - 12" v-model="selectedBlock"  @change="selectDateAndRoom($event)"/>
          </td>
          <td>
            <input class="form-check-input class13" type="radio" id="10:00:00-13" value="10:00:00 - 13" v-model="selectedBlock"  @change="selectDateAndRoom($event)"/>
          </td>
          <td>
            <input class="form-check-input class14" type="radio" id="10:00:00-14" value="10:00:00 - 14" v-model="selectedBlock"  @change="selectDateAndRoom($event)"/>
          </td>
        </tr>
        <tr>
          <th>11:30 - 12:30</th>
          <td>
            <input class="form-check-input class1" type="radio" id="11:30:00-1" value="11:30:00 - 1" v-model="selectedBlock"  @change="selectDateAndRoom($event)"/>
          </td>
          <td>
            <input class="form-check-input class2" type="radio" id="11:30:00-2" value="11:30:00 - 2" v-model="selectedBlock"  @change="selectDateAndRoom($event)"/>
          </td>
          <td>
            <input class="form-check-input class3" type="radio" id="11:30:00-3" value="11:30:00 - 3" v-model="selectedBlock"  @change="selectDateAndRoom($event)"/>
          </td>
          <td>
            <input class="form-check-input class4" type="radio" id="11:30:00-4" value="11:30:00 - 4" v-model="selectedBlock"  @change="selectDateAndRoom($event)"/>
          </td>
          <td>
            <input class="form-check-input class5" type="radio" id="11:30:00-5" value="11:30:00 - 5" v-model="selectedBlock"  @change="selectDateAndRoom($event)"/>
          </td>
          <td>
            <input class="form-check-input class6" type="radio" id="11:30:00-6" value="11:30:00 - 6" v-model="selectedBlock"  @change="selectDateAndRoom($event)"/>
          </td>
          <td>
            <input class="form-check-input class7" type="radio" id="11:30:00-7" value="11:30:00 - 7" v-model="selectedBlock"  @change="selectDateAndRoom($event)"/>
          </td>
          <td>
            <input class="form-check-input class8" type="radio" id="11:30:00-8" value="11:30:00 - 8" v-model="selectedBlock"  @change="selectDateAndRoom($event)"/>
          </td>
          <td>
            <input class="form-check-input class9" type="radio" id="11:30:00-9" value="11:30:00 - 9" v-model="selectedBlock"  @change="selectDateAndRoom($event)"/>
          </td>
          <td>
            <input class="form-check-input class10" type="radio" id="11:30:00-10" value="11:30:00 - 10" v-model="selectedBlock"  @change="selectDateAndRoom($event)"/>
          </td>
          <td>
            <input class="form-check-input class11" type="radio" id="11:30:00-11" value="11:30:00 - 11" v-model="selectedBlock"  @change="selectDateAndRoom($event)"/>
          </td>
          <td>
            <input class="form-check-input class12" type="radio" id="11:30:00-12" value="11:30:00 - 12" v-model="selectedBlock"  @change="selectDateAndRoom($event)"/>
          </td>
          <td>
            <input class="form-check-input class13" type="radio" id="11:30:00-13" value="11:30:00 - 13" v-model="selectedBlock"  @change="selectDateAndRoom($event)"/>
          </td>
          <td>
            <input class="form-check-input class14" type="radio" id="11:30:00-14" value="11:30:00 - 14" v-model="selectedBlock"  @change="selectDateAndRoom($event)"/>
          </td>
        </tr>
        <tr>
          <th>13:00 - 14:00</th>
          <td>
            <input class="form-check-input class1" type="radio" id="13:00:00-1" value="13:00:00 - 1" v-model="selectedBlock"  @change="selectDateAndRoom($event)"/>
          </td>
          <td>
            <input class="form-check-input class2" type="radio" id="13:00:00-2" value="13:00:00 - 2" v-model="selectedBlock"  @change="selectDateAndRoom($event)"/>
          </td>
          <td>
            <input class="form-check-input class3" type="radio" id="13:00:00-3" value="13:00:00 - 3" v-model="selectedBlock"  @change="selectDateAndRoom($event)"/>
          </td>
          <td>
            <input class="form-check-input class4" type="radio" id="13:00:00-4" value="13:00:00 - 4" v-model="selectedBlock"  @change="selectDateAndRoom($event)"/>
          </td>
          <td>
            <input class="form-check-input class5" type="radio" id="13:00:00-5" value="13:00:00 - 5" v-model="selectedBlock"  @change="selectDateAndRoom($event)"/>
          </td>
          <td>
            <input class="form-check-input class6" type="radio" id="13:00:00-6" value="13:00:00 - 6" v-model="selectedBlock"  @change="selectDateAndRoom($event)"/>
          </td>
          <td>
            <input class="form-check-input class7" type="radio" id="13:00:00-7" value="13:00:00 - 7" v-model="selectedBlock"  @change="selectDateAndRoom($event)"/>
          </td>
          <td>
            <input class="form-check-input class8" type="radio" id="13:00:00-8" value="13:00:00 - 8" v-model="selectedBlock"  @change="selectDateAndRoom($event)"/>
          </td>
          <td>
            <input class="form-check-input class9" type="radio" id="13:00:00-9" value="13:00:00 - 9" v-model="selectedBlock"  @change="selectDateAndRoom($event)"/>
          </td>
          <td>
            <input class="form-check-input class10" type="radio" id="13:00:00-10" value="13:00:00 - 10" v-model="selectedBlock"  @change="selectDateAndRoom($event)"/>
          </td>
          <td>
            <input class="form-check-input class11" type="radio" id="13:00:00-11" value="13:00:00 - 11" v-model="selectedBlock"  @change="selectDateAndRoom($event)"/>
          </td>
          <td>
            <input class="form-check-input class12" type="radio" id="13:00:00-12" value="13:00:00 - 12" v-model="selectedBlock"  @change="selectDateAndRoom($event)"/>
          </td>
          <td>
            <input class="form-check-input class13" type="radio" id="13:00:00-13" value="13:00:00 - 13" v-model="selectedBlock"  @change="selectDateAndRoom($event)"/>
          </td>
          <td>
            <input class="form-check-input class14" type="radio" id="13:00:00-14" value="13:00:00 - 14" v-model="selectedBlock"  @change="selectDateAndRoom($event)"/>
          </td>
        </tr>
        <tr>
          <th>14:30 - 15:30</th>
          <td>
            <input class="form-check-input class1" type="radio" id="14:30:00-1" value="14:30:00 - 1" v-model="selectedBlock"  @change="selectDateAndRoom($event)"/>
          </td>
          <td>
            <input class="form-check-input class2" type="radio" id="14:30:00-2" value="14:30:00 - 2" v-model="selectedBlock"  @change="selectDateAndRoom($event)"/>
          </td>
          <td>
            <input class="form-check-input class3" type="radio" id="14:30:00-3" value="14:30:00 - 3" v-model="selectedBlock"  @change="selectDateAndRoom($event)"/>
          </td>
          <td>
            <input class="form-check-input class4" type="radio" id="14:30:00-4" value="14:30:00 - 4" v-model="selectedBlock"  @change="selectDateAndRoom($event)"/>
          </td>
          <td>
            <input class="form-check-input class5" type="radio" id="14:30:00-5" value="14:30:00 - 5" v-model="selectedBlock"  @change="selectDateAndRoom($event)"/>
          </td>
          <td>
            <input class="form-check-input class6" type="radio" id="14:30:00-6" value="14:30:00 - 6" v-model="selectedBlock"  @change="selectDateAndRoom($event)"/>
          </td>
          <td>
            <input class="form-check-input class7" type="radio" id="14:30:00-7" value="14:30:00 - 7" v-model="selectedBlock"  @change="selectDateAndRoom($event)"/>
          </td>
          <td>
            <input class="form-check-input class8" type="radio" id="14:30:00-8" value="14:30:00 - 8" v-model="selectedBlock"  @change="selectDateAndRoom($event)"/>
          </td>
          <td>
            <input class="form-check-input class9" type="radio" id="14:30:00-9" value="14:30:00 - 9" v-model="selectedBlock"  @change="selectDateAndRoom($event)"/>
          </td>
          <td>
            <input class="form-check-input class10" type="radio" id="14:30:00-10" value="14:30:00 - 10" v-model="selectedBlock"  @change="selectDateAndRoom($event)"/>
          </td>
          <td>
            <input class="form-check-input class11" type="radio" id="14:30:00-11" value="14:30:00 - 11" v-model="selectedBlock"  @change="selectDateAndRoom($event)"/>
          </td>
          <td>
            <input class="form-check-input class12" type="radio" id="14:30:00-12" value="14:30:00 - 12" v-model="selectedBlock"  @change="selectDateAndRoom($event)"/>
          </td>
          <td>
            <input class="form-check-input class13" type="radio" id="14:30:00-13" value="14:30:00 - 13" v-model="selectedBlock"  @change="selectDateAndRoom($event)"/>
          </td>
          <td>
            <input class="form-check-input class14" type="radio" id="14:30:00-14" value="14:30:00 - 14" v-model="selectedBlock"  @change="selectDateAndRoom($event)"/>
          </td>
        </tr>
        <tr>
          <th>16:00 - 17:00</th>
          <td>
            <input class="form-check-input class1" type="radio" id="16:00:00-1" value="16:00:00 - 1" v-model="selectedBlock"  @change="selectDateAndRoom($event)"/>
          </td>
          <td>
            <input class="form-check-input class2" type="radio" id="16:00:00-2" value="16:00:00 - 2" v-model="selectedBlock"  @change="selectDateAndRoom($event)"/>
          </td>
          <td>
            <input class="form-check-input class3" type="radio" id="16:00:00-3" value="16:00:00 - 3" v-model="selectedBlock"  @change="selectDateAndRoom($event)"/>
          </td>
          <td>
            <input class="form-check-input class4" type="radio" id="16:00:00-4" value="16:00:00 - 4" v-model="selectedBlock"  @change="selectDateAndRoom($event)"/>
          </td>
          <td>
            <input class="form-check-input class5" type="radio" id="16:00:00-5" value="16:00:00 - 5" v-model="selectedBlock"  @change="selectDateAndRoom($event)"/>
          </td>
          <td>
            <input class="form-check-input class6" type="radio" id="16:00:00-6" value="16:00:00 - 6" v-model="selectedBlock"  @change="selectDateAndRoom($event)"/>
          </td>
          <td>
            <input class="form-check-input class7" type="radio" id="16:00:00-7" value="16:00:00 - 7" v-model="selectedBlock"  @change="selectDateAndRoom($event)"/>
          </td>
          <td>
            <input class="form-check-input class8" type="radio" id="16:00:00-8" value="16:00:00 - 8" v-model="selectedBlock"  @change="selectDateAndRoom($event)"/>
          </td>
          <td>
            <input class="form-check-input class9" type="radio" id="16:00:00-9" value="16:00:00 - 9" v-model="selectedBlock"  @change="selectDateAndRoom($event)"/>
          </td>
          <td>
            <input class="form-check-input class10" type="radio" id="16:00:00-10" value="16:00:00 - 10" v-model="selectedBlock"  @change="selectDateAndRoom($event)"/>
          </td>
          <td>
            <input class="form-check-input class11" type="radio" id="16:00:00-11" value="16:00:00 - 11" v-model="selectedBlock"  @change="selectDateAndRoom($event)"/>
          </td>
          <td>
            <input class="form-check-input class12" type="radio" id="16:00:00-12" value="16:00:00 - 12" v-model="selectedBlock"  @change="selectDateAndRoom($event)"/>
          </td>
          <td>
            <input class="form-check-input class13" type="radio" id="16:00:00-13" value="16:00:00 - 13" v-model="selectedBlock"  @change="selectDateAndRoom($event)"/>
          </td>
          <td>
            <input class="form-check-input class14" type="radio" id="16:00:00-14" value="16:00:00 - 14" v-model="selectedBlock"  @change="selectDateAndRoom($event)"/>
          </td>
        </tr>
        <tr>
          <th>17:30 - 18:30</th>
          <td>
            <input class="form-check-input class1" type="radio" id="17:30:00-1" value="17:30:00 - 1" v-model="selectedBlock"  @change="selectDateAndRoom($event)"/>
          </td>
          <td>
            <input class="form-check-input class2" type="radio" id="17:30:00-2" value="17:30:00 - 2" v-model="selectedBlock"  @change="selectDateAndRoom($event)"/>
          </td>
          <td>
            <input class="form-check-input class3" type="radio" id="17:30:00-3" value="17:30:00 - 3" v-model="selectedBlock"  @change="selectDateAndRoom($event)"/>
          </td>
          <td>
            <input class="form-check-input class4" type="radio" id="17:30:00-4" value="17:30:00 - 4" v-model="selectedBlock"  @change="selectDateAndRoom($event)"/>
          </td>
          <td>
            <input class="form-check-input class5" type="radio" id="17:30:00-5" value="17:30:00 - 5" v-model="selectedBlock"  @change="selectDateAndRoom($event)"/>
          </td>
          <td>
            <input class="form-check-input class6" type="radio" id="17:30:00-6" value="17:30:00 - 6" v-model="selectedBlock"  @change="selectDateAndRoom($event)"/>
          </td>
          <td>
            <input class="form-check-input class7" type="radio" id="17:30:00-7" value="17:30:00 - 7" v-model="selectedBlock"  @change="selectDateAndRoom($event)"/>
          </td>
          <td>
            <input class="form-check-input class8" type="radio" id="17:30:00-8" value="17:30:00 - 8" v-model="selectedBlock"  @change="selectDateAndRoom($event)"/>
          </td>
          <td>
            <input class="form-check-input class9" type="radio" id="17:30:00-9" value="17:30:00 - 9" v-model="selectedBlock"  @change="selectDateAndRoom($event)"/>
          </td>
          <td>
            <input class="form-check-input class10" type="radio" id="17:30:00-10" value="17:30:00 - 10" v-model="selectedBlock"  @change="selectDateAndRoom($event)"/>
          </td>
          <td>
            <input class="form-check-input class11" type="radio" id="17:30:00-11" value="17:30:00 - 11" v-model="selectedBlock"  @change="selectDateAndRoom($event)"/>
          </td>
          <td>
            <input class="form-check-input class12" type="radio" id="17:30:00-12" value="17:30:00 - 12" v-model="selectedBlock"  @change="selectDateAndRoom($event)"/>
          </td>
          <td>
            <input class="form-check-input class13" type="radio" id="17:30:00-13" value="17:30:00 - 13" v-model="selectedBlock"  @change="selectDateAndRoom($event)"/>
          </td>
          <td>
            <input class="form-check-input class14" type="radio" id="17:30:00-14" value="17:30:00 - 14" v-model="selectedBlock"  @change="selectDateAndRoom($event)"/>
          </td>
        </tr>
        <tr>
          <th>19:00 - 20:00</th>
          <td>
            <input class="form-check-input class1" type="radio" id="19:00:00-1" value="19:00:00 - 1" v-model="selectedBlock"  @change="selectDateAndRoom($event)"/>
          </td>
          <td>
            <input class="form-check-input class2" type="radio" id="19:00:00-2" value="19:00:00 - 2" v-model="selectedBlock"  @change="selectDateAndRoom($event)"/>
          </td>
          <td>
            <input class="form-check-input class3" type="radio" id="19:00:00-3" value="19:00:00 - 3" v-model="selectedBlock"  @change="selectDateAndRoom($event)"/>
          </td>
          <td>
            <input class="form-check-input class4" type="radio" id="19:00:00-4" value="19:00:00 - 4" v-model="selectedBlock"  @change="selectDateAndRoom($event)"/>
          </td>
          <td>
            <input class="form-check-input class5" type="radio" id="19:00:00-5" value="19:00:00 - 5" v-model="selectedBlock"  @change="selectDateAndRoom($event)"/>
          </td>
          <td>
            <input class="form-check-input class6" type="radio" id="19:00:00-6" value="19:00:00 - 6" v-model="selectedBlock"  @change="selectDateAndRoom($event)"/>
          </td>
          <td>
            <input class="form-check-input class7" type="radio" id="19:00:00-7" value="19:00:00 - 7" v-model="selectedBlock"  @change="selectDateAndRoom($event)"/>
          </td>
          <td>
            <input class="form-check-input class8" type="radio" id="19:00:00-8" value="19:00:00 - 8" v-model="selectedBlock"  @change="selectDateAndRoom($event)"/>
          </td>
          <td>
            <input class="form-check-input class9" type="radio" id="19:00:00-9" value="19:00:00 - 9" v-model="selectedBlock"  @change="selectDateAndRoom($event)"/>
          </td>
          <td>
            <input class="form-check-input class10" type="radio" id="19:00:00-10" value="19:00:00 - 10" v-model="selectedBlock"  @change="selectDateAndRoom($event)"/>
          </td>
          <td>
            <input class="form-check-input class11" type="radio" id="19:00:00-11" value="19:00:00 - 11" v-model="selectedBlock"  @change="selectDateAndRoom($event)"/>
          </td>
          <td>
            <input class="form-check-input class12" type="radio" id="19:00:00-12" value="19:00:00 - 12" v-model="selectedBlock"  @change="selectDateAndRoom($event)"/>
          </td>
          <td>
            <input class="form-check-input class13" type="radio" id="19:00:00-13" value="19:00:00 - 13" v-model="selectedBlock"  @change="selectDateAndRoom($event)"/>
          </td>
          <td>
            <input class="form-check-input class14" type="radio" id="19:00:00-14" value="19:00:00 - 14" v-model="selectedBlock"  @change="selectDateAndRoom($event)"/>
          </td>
        </tr>
        <tr>
          <th>20:00 - 21:00</th>
          <td>
            <input class="form-check-input class1" type="radio" id="20:00:00-1" value="20:00:00 - 1" v-model="selectedBlock"  @change="selectDateAndRoom($event)"/>
          </td>
          <td>
            <input class="form-check-input class2" type="radio" id="20:00:00-2" value="20:00:00 - 2" v-model="selectedBlock"  @change="selectDateAndRoom($event)"/>
          </td>
          <td>
            <input class="form-check-input class3" type="radio" id="20:00:00-3" value="20:00:00 - 3" v-model="selectedBlock"  @change="selectDateAndRoom($event)"/>
          </td>
          <td>
            <input class="form-check-input class4" type="radio" id="20:00:00-4" value="20:00:00 - 4" v-model="selectedBlock"  @change="selectDateAndRoom($event)"/>
          </td>
          <td>
            <input class="form-check-input class5" type="radio" id="20:00:00-5" value="20:00:00 - 5" v-model="selectedBlock"  @change="selectDateAndRoom($event)"/>
          </td>
          <td>
            <input class="form-check-input class6" type="radio" id="20:00:00-6" value="20:00:00 - 6" v-model="selectedBlock"  @change="selectDateAndRoom($event)"/>
          </td>
          <td>
            <input class="form-check-input class7" type="radio" id="20:00:00-7" value="20:00:00 - 7" v-model="selectedBlock"  @change="selectDateAndRoom($event)"/>
          </td>
          <td>
            <input class="form-check-input class8" type="radio" id="20:00:00-8" value="20:00:00 - 8" v-model="selectedBlock"  @change="selectDateAndRoom($event)"/>
          </td>
          <td>
            <input class="form-check-input class9" type="radio" id="20:00:00-9" value="20:00:00 - 9" v-model="selectedBlock"  @change="selectDateAndRoom($event)"/>
          </td>
          <td>
            <input class="form-check-input class10" type="radio" id="20:00:00-10" value="20:00:00 - 10" v-model="selectedBlock"  @change="selectDateAndRoom($event)"/>
          </td>
          <td>
            <input class="form-check-input class11" type="radio" id="20:00:00-11" value="20:00:00 - 11" v-model="selectedBlock"  @change="selectDateAndRoom($event)"/>
          </td>
          <td>
            <input class="form-check-input class12" type="radio" id="20:00:00-12" value="20:00:00 - 12" v-model="selectedBlock"  @change="selectDateAndRoom($event)"/>
          </td>
          <td>
            <input class="form-check-input class13" type="radio" id="20:00:00-13" value="20:00:00 - 13" v-model="selectedBlock"  @change="selectDateAndRoom($event)"/>
          </td>
          <td>
            <input class="form-check-input class14" type="radio" id="20:00:00-14" value="20:00:00 - 14" v-model="selectedBlock"  @change="selectDateAndRoom($event)"/>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
  <div class="option-home d-flex flex-column justify-content-center" v-if="this.category == 'home'">
    <h4 class="text-center">Home Office</h4>
    <div class="w-100 container">
      <div class="d-flex flex-row justify-content-around">
        <span> Horários: </span>
        <select class="ms-2 form-select-md selectH" aria-label="Mês" @change="selectDateAndRoom($event)" ref="home">
          <option value="">Horario</option>
          <option value="08:30:00 - 09:30" class="selectHours" id="08:30:00h">08:30 - 09:30</option>
          <option value="10:00:00 - 11:00" class="selectHours" id="10:00:00h">10:00 - 11:00</option>
          <option value="11:30:00 - 12:30" class="selectHours" id="11:30:00h">11:30 - 12:30</option>
          <option value="13:00:00 - 14:00" class="selectHours" id="13:00:00h">13:00 - 14:00</option>
          <option value="14:30:00 - 15:30" class="selectHours" id="14:30:00h">14:30 - 15:30</option>
          <option value="16:00:00 - 17:00" class="selectHours" id="16:00:00h">16:00 - 17:00</option>
          <option value="17:30:00 - 18:30" class="selectHours" id="17:30:00h">17:30 - 18:30</option>
          <option value="19:00:00 - 20:00" class="selectHours" id="19:00:00h">19:00 - 20:00</option>
          <option value="20:00:00 - 21:00" class="selectHours" id="20:00:00h">20:00 - 21:00</option>
        </select>
      </div>
    </div>
  </div>
  <div class="option-pos" v-if="this.category == 'pos'">
    <h4 class="text-center">Graduação ao vivo</h4>
    <div class="w-100 container">
      <div class="d-flex flex-row justify-content-around">
        <span> Horários: </span>
        <select class="ms-2 form-select-md" aria-label="Mês" ref="pos" @change="selectDateAndRoom($event)">
          <option value="">Horario</option>
          <option value="08:30:00 - 09:30" class="selectHours" id="08:30:00p">08:30 - 11:00</option>
          <option value="13:00:00 - 14:00" class="selectHours" id="13:00:00p">13:00 - 15:30</option>
          <option value="19:00:00 - 20:00" class="selectHours" id="19:00:00p">19:00 - 21:30</option>
        </select>
      </div>
    </div>
  </div>
  <div class="buttons-blockhour d-flex justify-content-center py-3">
    <button class="btn btn-primary ms-2" type="button" @click="bloquearHorario" id="botaoBlockHour">Bloquear</button>
    <button class="btn btn-primary ms-2 block-sala" type="button" @click="bloquarSala" id="botaoBlockDay" v-if="this.category == 'presencial'">Desativar</button>
  </div>
</div>
</template>

<script>
import alert from '../../plugins/alert'
import axios from 'axios';

export default {
  name: 'BlockHours',
  data() {
    return {
      category: '',
      selectedBlock: null,
      selectedDayBlock: null,
      roomsBlock: null,
      horariosBlock: null
    }
  },
  methods: {
    selectDateAndRoom(event) {
      this.selectedBlock = event.target.value;
      this.verificaBlockHours();
    },
    selectDateBlock(ev) {
      Array.from(document.querySelectorAll('.form-check-input')).forEach(el => {
        // el.disabled = false;
        el.classList.remove('block-day-pre')
      }) 

      this.selectedDayBlock = ev.target.value;

      Array.from(document.querySelectorAll(`.class${ev.target.value}`)).forEach(el => {
        el.classList.add('block-day-pre')
        // el.disabled = true
      }) 

      this.verificaBlockDay();
    },
    verificaBlockHours() {
      let bb = '';
      let tab = document.querySelector('.form-select-md')

      if(tab.value == 'presencial'){
        bb = this.selectedBlock.replace(/\s/g, '');
      }
      
      if(tab.value == 'home'){
        bb = this.selectedBlock.split(" ")
        bb = bb[0]+'h'
        console.log(bb)
      }
      
      if(tab.value == 'pos'){
        bb = this.selectedBlock.split(" ")
        bb = bb[0]+'p'
        console.log(bb)
      }
      
      if(document.getElementById(bb).classList.contains('block-hours')) {
        document.getElementById('botaoBlockHour').innerHTML = 'Desbloquear';
      }  else {
        document.getElementById('botaoBlockHour').innerHTML = 'Bloquear';
      }
    },
    verificaBlockDay() {
      let dayBlock = this.selectedDayBlock
      if(document.getElementById(dayBlock).classList.contains('block-day')) {
        document.getElementById('botaoBlockDay').innerHTML = 'Ativar';
      }  else {
        console.log('opaaaa')
        document.getElementById('botaoBlockDay').innerHTML = 'Desativar';
      }
    },
    legendaBlock() {
      let course_idS = localStorage.getItem('course_id');
      let id = localStorage.getItem('id');
      let dataBlock = document.getElementById('dataBlock').value;
      let data = document.getElementById('dataBlock').valueAsDate;
      let ano =  data.getUTCFullYear();
      let mes = data.getUTCMonth() + 1
      let tab = document.querySelector('.form-select-md')

      axios.post(`${process.env.VUE_APP_ROOT_API}/zoom/dates`, {
        month: mes,
        year: ano,
        user_id: id,
        // course_id: course_idS
      }).then((resposta) => {
        console.log(resposta.data.datesNotAllowed);
        let roomsBlocks = resposta.data.datesNotAllowed.allDay;
        let horariosBlocks = resposta.data.datesNotAllowed.dates;
        let horaBlock = '';
        let salaDayBlock = '';

        Array.from(document.querySelectorAll('.form-check-input')).forEach (el => {
          el.classList.remove('block-hours');
          el.classList.remove('block-day');
          el.disabled = false;
        });

        Array.from(document.querySelectorAll('.selectHours')).forEach (el => {
          el.classList.remove('block-hours');
        });

        if(tab.value == 'presencial') {
          roomsBlocks.forEach(index => {
            salaDayBlock = index.date.split(" ")
            console.log('ROOM ID A SER BLOQUEADO: ' + index.room_id);
            console.log('ID DO BLOCK: ' + index.id)
            console.log('Dia do block: ' + salaDayBlock[0])
            if(dataBlock == salaDayBlock[0]) {
              document.getElementById(index.room_id).classList.add('block-day')
              document.getElementById(index.room_id).setAttribute("name", index.id);
              Array.from(document.querySelectorAll(`.class${index.room_id}`)).forEach(el => {
                el.classList.add('block-hours')
                el.disabled = true
              }) 
            }
          });
        }

        horariosBlocks.forEach(index => {
          horaBlock = index.date.split(" ");
          if(dataBlock == horaBlock[0]) {
            if(tab.value == 'presencial') {
              if(index.room_id) {
                document.getElementById(horaBlock[1] + '-' + index.room_id).classList.add('block-hours');
                document.getElementById(horaBlock[1] + '-' + index.room_id).setAttribute("name", index.id);
                console.log(index)
              }
            } if(tab.value == 'home') {
                if(!index.room_id) {
                  if(index.type_meeting == 'home') {
                    document.getElementById(horaBlock[1]+'h').classList.add('block-hours')
                    document.getElementById(horaBlock[1]+'h').setAttribute("name", index.id)
                    console.log(index.id)
                  } 
                }
            }

            if(tab.value == 'pos') {
              if(!index.room_id) {
                  if(index.type_meeting == 'live') {
                    document.getElementById(horaBlock[1]+'p').classList.add('block-hours')
                    document.getElementById(horaBlock[1]+'p').setAttribute("name", index.id)
                    console.log(index.id)
                  } 
              }
            }
          }
        })
      }).catch((error) => {
        console.log(error);
      });
    },
    bloquarSala() {
      let course_idS = localStorage.getItem('course_id');
      let user_idS = localStorage.getItem('user_id');
      let id = localStorage.getItem('id');

      let dia = document.getElementById('dataBlock').value;
      dia = dia + ' ' + '08:30:00';

      let active = document.getElementById('botaoBlockDay').innerHTML == 'Ativar'? true : false

      console.log('ENTROU NO ALLDAY');

      if (!active) {
        this.$loading(true);
        console.log('VOCE FOI BLOQUEADO NO ALLDAY')
        console.log('SALA DO DAYBLOCK: ' + this.selectedDayBlock)
        console.log('DIA DO ALLDAY BLOCK: ' + dia)
        axios.post(`${process.env.VUE_APP_ROOT_API}/admin/blackboard/dates/notallowed`, {
          date: dia,
          allDay: true,
          room_id: this.selectedDayBlock,
          type_meeting: "cabin",
          user_id:  id,
          course_id: course_idS,
        }).then((resposta) => {
          alert.fireAlert('Sala(s) desativada(s) com sucesso!', {
          classes: 'alert-success bi bi-check-circle',
          styles:
            'background-color: #d4edda; border-color: #c3e6cb; color: #155724;',
        });
          console.log(resposta)
          this.legendaBlock()
        }).catch((error) => {
          console.error(error);
          console.log(`ERROR USER ID: ${id}`);
          console.log(`ERROR COURSE ID: ${course_idS}`);
          alert.fireAlert(`Não foi possível desativar a(s) sala(s).`, {
            classes: 'alert-danger bi bi-exclamation-circle',
            styles:
              'background-color: #f8d7da; border-color: #F5C6CB; color: #721C24;',
            tag: 'httpAlert',
          });
        }).finally(() => {
          this.$loading(false);
        });
      } else {
        console.log('VOCE SERA DESBLOQUEADO NO ALLDAY')
        let idHorario = document.getElementById(this.selectedDayBlock);
        console.log(idHorario.name);
        console.log(id)
        this.$loading(true);
        axios.delete(`${process.env.VUE_APP_ROOT_API}/admin/blackboard/dates/notallowed?id=${idHorario.name}&user_id=${id}`, {
        }).then((resposta) => {
          console.log('desbloqueado com sucesso')
          this.legendaBlock();
          alert.fireAlert('Sala(s) desbloqueada(s) com sucesso!', {
            classes: 'alert-success bi bi-check-circle',
            styles:
              'background-color: #d4edda; border-color: #c3e6cb; color: #155724;',
          });
        }).catch((error) => {
          console.error(error);
          console.log(`ERROR USER ID: ${user_idS}`);
          console.log(`ERROR COURSE ID: ${course_idS}`);
          alert.fireAlert(`Não foi possível desbloquear a(s) sala(s).`, {
            classes: 'alert-danger bi bi-exclamation-circle',
            styles:
              'background-color: #f8d7da; border-color: #F5C6CB; color: #721C24;',
            tag: 'httpAlert',
          });
        }).finally(() => {
          this.$loading(false);
        });
      }
    },
    bloquearHorario() {
      let course_idS = localStorage.getItem('course_id');
      let user_idS = localStorage.getItem('user_id');
      let id = localStorage.getItem('id');
      let typeMeeting = 'cabin';
      let tab = document.querySelector('.form-select-md')
      let tabSelect = tab.value == 'presencial' ? true : false
      let valueHomeOrPos = '';

      if(tab.value == 'home') {
        valueHomeOrPos = this.$refs.home.value.substring(0, 8)
        typeMeeting = 'home'
      }
      
      if(tab.value == 'pos') {
        valueHomeOrPos = this.$refs.pos.value.substring(0, 8);
        typeMeeting = 'live'
      }

      let horario = tabSelect ?  this.selectedBlock.substring(0, 8) : valueHomeOrPos;
      let room = tabSelect ? this.selectedBlock.substring(11, 18) : null;
      let dia = document.getElementById('dataBlock').value;

      // console.log('Horario: ' + horario);
      // console.log('Sala: ' + room);
      // console.log('Data: ' + dia);
      // console.log('Course ID: ' + course_idS);
      // console.log('User ID: ' + user_idS);
      let horarioBlock = dia + ' ' + horario;
      // console.log('date: ' + horarioBlock);

      let testeApi = {}

      if(room) {
        testeApi = {
        date: horarioBlock,
        room_id: room,
        type_meeting: typeMeeting,
        user_id: id,
        course_id: course_idS
        }
      } else {
        testeApi = {
        date: horarioBlock,
        type_meeting: typeMeeting,
        user_id: id,
        course_id: course_idS
        }
      }

      // console.log(testeApi)

      if (document.getElementById('botaoBlockHour').innerHTML == 'Bloquear') {
        this.$loading(true);
        console.log('VOCE FOI BLOQUEADO...............')
        axios.post(`${process.env.VUE_APP_ROOT_API}/admin/blackboard/dates/notallowed`, testeApi
        ).then((resposta) => {
          console.log('bloqueado com sucesso')
          console.log(resposta)
          this.legendaBlock();
          alert.fireAlert('Horário(s) bloqueado(s) com sucesso!', {
            classes: 'alert-success bi bi-check-circle',
            styles:
              'background-color: #d4edda; border-color: #c3e6cb; color: #155724;',
          });
        }).catch((error) => {
          alert.fireAlert(`Não foi possível bloquear o(s) horário(s).`, {
            classes: 'alert-danger bi bi-exclamation-circle',
            styles:
              'background-color: #f8d7da; border-color: #F5C6CB; color: #721C24;',
            tag: 'httpAlert',
          });
          console.error(error);
          console.log(`ERROR USER ID: ${id}`);
          console.log(`ERROR COURSE ID: ${course_idS}`);
        }).finally(() => {
          this.$loading(false);
        });
      } else {
        console.log('VOCE SERA DESBLOQUEADO.......');
        let bb = '';
        let tab = document.querySelector('.form-select-md')

        if(tab.value == 'presencial'){
          bb = this.selectedBlock.replace(/\s/g, '');
        }
      
        if(tab.value == 'home'){
          bb = this.selectedBlock.split(" ")
          bb = bb[0]+'h'
          console.log(bb)
        }
      
        if(tab.value == 'pos'){
          bb = this.selectedBlock.split(" ")
          bb = bb[0]+'p'
          console.log(bb)
        }

        let idHorario = document.getElementById(bb);
        idHorario = idHorario.getAttribute('name')
        console.log('ID HORARIO: ' + bb);
        console.log(id)
        this.$loading(true);
        axios.delete(`${process.env.VUE_APP_ROOT_API}/admin/blackboard/dates/notallowed?id=${idHorario}&user_id=${id}`, {
        }).then((resposta) => {
          console.log('desbloqueado com sucesso')
          this.legendaBlock();
          alert.fireAlert('Horário(s) desbloqueado(s) com sucesso!', {
            classes: 'alert-success bi bi-check-circle',
            styles:
              'background-color: #d4edda; border-color: #c3e6cb; color: #155724;',
          });
        }).catch((error) => {
          alert.fireAlert(`Não foi possível desbloquear o horário.`, {
            classes: 'alert-danger bi bi-exclamation-circle',
            styles:
              'background-color: #f8d7da; border-color: #F5C6CB; color: #721C24;',
            tag: 'httpAlert',
          });
          console.error(error);
          console.log(`ERROR USER ID: ${user_idS}`);
          console.log(`ERROR COURSE ID: ${course_idS}`);
        }).finally(() => {
          this.$loading(false);
        });
      }
    },
  }
};
</script>

<style scoped>
.blockhour-tab {
  background-color: lightblue;
}
.table-titles {
  color: #FFF;
  background-color: var(--azul-tres);
}
table {
  border-collapse: collapse;
  /* border-radius: 15px; */
  border-style: hidden /* hide standard table (collapsed) border */;
  box-shadow: 0 0 0 1px #666 /* this draws the table border  */;
  background-color: #fff;
  height: 40%;
  width: auto;
}
table th {
  padding: 0px 10px;
  text-align: center;
  border: 1px solid #ccc;
}
table td {
  padding: 0px 10px;
  text-align: center;
  border: 1px solid #ccc;
}
.block-hours {
  background-color: #cbccd0;
  border-color: #cbccd0;
}
.block-day {
  background-color: #cbccd0;
  border-color: #cbccd0;
}
.block-sala {
  background-color: red;
  border-color: red
}
.block-day-pre {
  background-color: #cbccd0;
  border-color: #cbccd0;
}
.info-data {
  font-size: 16px;
  text-align: center;
  margin-bottom: 20px;
}
.info-title-deactive {
  font-weight: bold;
  color: #808080;
  background-color: #d3d3d3;
}
.bd-all {
  border: 1px solid #000;
}
.legD {
  padding-left: 24px !important;
}
</style>
