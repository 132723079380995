<template>
<modal class="text-center" id="create-user-modal" :buttonColor="buttonColor" :loading="loading" close-button-label="Sair" ref="modal" size="md" title="TELA DE CRIAÇÃO DE USUÁRIO">
  <div class="d-flex flex-column">
    <div>
      <h5>Criar usuário: </h5>
    </div>
    <form class="create-user-inputs">
      <div class="d-flex justify-content-between flex-row">
        <label class="form-label">User Name: </label>
        <input class="ms-2" type="text" placeholder="Digite a User Name" v-model="userNameWrited"/>
      </div>
       <div class="d-flex justify-content-between flex-row mt-2">
        <label for="">Permissão: </label>
        <select v-model="roleSelected">
          <option disabled value="">Escolha uma permissão</option>
          <option v-for="role in roles" :key="role.id">{{role.id}} - {{role.role}}</option>
        </select>
      </div>
      <button class="btn btn-primary mt-4" type="button" @click="createUser">Criar conta</button>
    </form>
  </div>
</modal>
</template>

<script>
import Modal from '../../ui/Modal.vue';
import axios from 'axios';
import alert from '../../plugins/alert'

export default {
  name: 'CreateUserModal',
  components: {
    Modal,
  },
  data() {
    return {
      loading: false,
      buttonColor: true,
      roles: [],
      roleSelected: '',
      userNameWrited: '',
      user_id: '',
    };
  },
  beforeCreate() {
    let id = localStorage.getItem('id');
    this.user_id = id;
    this.$loading(true);
    axios.post((`${process.env.VUE_APP_ROOT_API}/admin/roles`), {
      user_id: id,
    }).then((res) => {
      this.roles = res.data.roles;
    }).catch((error) => {
    }).finally(() => {
      this.$loading(false);
    });
  },
  methods: {
    createUser() {
      var idRole = this.roleSelected.substring(0, 1);
      this.$loading(true);
      let id = localStorage.getItem('id');
      axios.post((`${process.env.VUE_APP_ROOT_API}/admin/user`), {
        user_id: id,
        user_name: this.userNameWrited,
        role_id: idRole,
      }).then((res) => {
        alert.fireAlert('Usuário criado com sucesso!', {
            classes: 'alert-success bi bi-check-circle',
            styles:
              'background-color: #d4edda; border-color: #c3e6cb; color: #155724;',
        });
      }).catch((error) => {
        alert.fireAlert(
            `Não foi possível criar o usuário.`,
            {
              classes: 'alert-danger bi bi-exclamation-circle',
              styles:
                'background-color: #f8d7da; border-color: #F5C6CB; color: #721C24;',
              tag: 'httpAlert',
            }
          );
      }).finally(() => {
        this.$loading(false);
        this.$refs.modal.close();
        this.$emit('refresh')
        // window.location.reload();
      });
    },
  }
};
</script>

<style>
.modal-header {
  background-color: #2D8CFF;
}
.close {
  display: none;
}
.modal-title {
  font-weight: bold;
  color: #FFF;
  margin-left: 2rem;
}
.modal-footer {
  background-color: #2D8CFF;
}
.create-user-inputs {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}
</style>