<template>
<div class="folder w-100">
  <div class="folder-container container">
    <div class="admin-options">
      <div>
        <h5 class="pt-3">Alteração do nome padrão da pasta nos cursos: </h5>
        <div class="info-folder">
          <p class="ms-2 me-2">Ao alterar o nome, as pastas com o nome anterior contidas nos cursos serão renomeadas para o novo nome informado.<br>
            O processo de alteração não irá diferencias letras maiúsculas de minúsculas.<br>
            O nome definido será o nome da pasta ao qual as gravações serão compartilhadas.<br>
          </p>
        </div>
      </div>
      <form class="mt-2">
        <h6>Nome da pasta:</h6>
        <input type="text" name="Nome-da-pasta" placeholder="Digite o nome da pasta" v-model="newFolderName" required>
        <h6 class="mt-2">Descrição da pasta da pasta:</h6>
        <input type="text" name="Nome-da-pasta" placeholder="Digite a descrição da pasta" v-model="newDescriptionName" required>
      </form>
      <button class="btn btn-primary mb-4 mt-4" @click="changeFolderName">Salvar</button>
    </div>
  </div>
</div>
</template>

<script>
import axios from 'axios';
import alert from '../../plugins/alert'

export default {
  name: 'Folder',
  data() {
    return {
      newFolderName: '',
      newDescriptionName: '',
    };
  },
  methods: {
    changeFolderName() {
      let id = localStorage.getItem('id')
      this.$loading(true);
      axios.post((`${process.env.VUE_APP_ROOT_API}/admin/blackboard/folder/update`), {
        folder_name: this.newFolderName,
        description: this.newDescriptionName,
        user_id: id
      }).then((resposta) => {
        console.log(resposta ,'sucesso da troca de nome da pasta');
        alert.fireAlert('Nome da pasta alterado sucesso!', {
          classes: 'alert-success bi bi-check-circle',
          styles:
            'background-color: #d4edda; border-color: #c3e6cb; color: #155724;',
        });
      }).catch((error) => {
        console.log(error ,'erro da troca de nome da pasta');
        alert.fireAlert(`Não foi possível renomear a pasta sem descrição.`, {
          classes: 'alert-danger bi bi-exclamation-circle',
          styles:
            'background-color: #f8d7da; border-color: #F5C6CB; color: #721C24;',
          tag: 'httpAlert',
        });
      }).finally(() => {
        this.$loading(false);
      });
    },
  },
};
</script>

<style scoped>
.folder {
  background-color: lightblue;
}

.info-folder {
  border: 1px solid lightcoral;
  color: lightcoral;
  background-color: lightyellow;
  max-width: 700px;
}

</style>
