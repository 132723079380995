<template>
<modal
  class="text-center"
  id="modal-cancel-meeting"
  :loading="loading"
  ref="modal"
  size="md"
  title="CANCELAMENTO DE REUNIAO"
  @process='cancelMeeting'
  process-button-label="Confirmar"
  processButtonClass="btn-primary"
  close-button-label="Sair"
  >
  <div class="text-start container">
    <i class="bi bi-x-circle"> - </i>
    <span>Deseja realmente cancelar a reunião do dia {{this.data}} ?</span>
  </div>
</modal>
</template>

<script>
import alert from '../../plugins/alert'
import Modal from '../../ui/Modal.vue';
import axios from 'axios';

export default {
  name: 'CancelMeetingModal',
  components: {
    Modal,
  },
  props: {
    meetingId: String,
    data: String,
  },
  data() {
    return {
      loading: false,
      buttonColor: true,
    };
  },
  methods: {
    cancelMeeting() {
      this.$loading(true);
      const id = localStorage.getItem('id');
      axios.post(`${process.env.VUE_APP_ROOT_API}/zoom/cancel`, {
        user_id: id,
        meetingId: this.meetingId,
      }).then(() => {
        document.getElementsByClassName('button__list')[0].click()
        alert.fireAlert('Aula deletada com sucesso!', {
          classes: 'alert-success bi bi-check-circle',
          styles:
            'background-color: #d4edda; border-color: #c3e6cb; color: #155724;',
        });
        this.$refs.modal.close();
        this.$emit('cancel');
      }).catch((err) => {
        const er = err.response.data.clientMessage
        const er2 = err.response.data.developerMessage
        alert.fireAlert(er, {
          classes: 'alert-danger bi bi-exclamation-circle',
          styles:
            'background-color: #f8d7da; border-color: #F5C6CB; color: #721C24;',
          tag: 'httpAlert',
        });
        this.$refs.modal.close();
      }).finally(() => {
        this.$loading(false);
      })
    }
  },
};
</script>

<style scoped>

.modal-header {
  background-color: red;
}
.close {
  display: none;
}
.modal-title {
  font-weight: bold;
  color: #FFF;
  margin-left: 2rem;
}

::v-deep .btn-primary {
  background-color: #008000 !important;
}

</style>