<template>
<div class="alert" :class="classes" :style="styles" onclick="$(this).alert(&quot;close&quot;);" role="alert">
  <span class="text ms-1">{{ text }}</span>
</div>
</template>

<script>

export default {
  name: 'AlertSnackBar',
  props: {
    text: String,
    icon: String,
    classes: String,
    styles: String,
  },
};
</script>
<style scoped>
.alert {
  font-weight: 400;
  border-color: inherit;
  border-radius: 4px;
  background-color: transparent;
}

.alert > i {
  margin-right: 5px;
}
.alert-danger:before {
  background-color: transparent;
}

</style>