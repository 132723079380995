import Loading from './Loading.vue';

let vm = {};

export default {
  install(Vue, opts) {
    const dataOpts = opts || {};
    const LoadingPlugin = Vue.extend(Loading);
    vm = new LoadingPlugin({
      data: dataOpts,
    }).$mount();
    document.body.appendChild(vm.$el);
    /* eslint no-param-reassign: "error" */
    Vue.prototype.$loading = (loading) => {
      vm.loading = loading;
    };
  },
};
