<template>
<div class="reports w-100 d-flex flex-column">
  <div class="d-flex flex-row mb-2 mt-4 justify-content-center">
    <span class="mt-1">De:</span>
    <input id="dateInicial" type="date"/>
    <span class="mt-1 ms-3">Para:</span>
    <input id="dateFinal" type="date"/>
    <!-- <span class="mt-1 ms-3">MeetindID</span>
    <input type="text" placeholder="Digite aqui o ID" id=""> -->
  </div>
  <div class="column-medium justify-content-center d-flex flex-row mt-3">
    <button class="btn btn-primary me-3" @click="gerarExportsList(listMeetingsExport)">Exportar</button>
    <button class="btn btn-primary" @click="gerarRelatorio">Pesquisar</button>
  </div>
  <p>{{ $store.state.objMeetingId }}</p>
  <div class="table-reports justify-content-center d-flex flex-row mt-4 container w-100">
    <table class="mb-5" v-if="this.searched">
      <thead class="table-titles">
        <tr>
          <th>Professor</th>
          <th>E-mail</th>
          <th>Curso</th>
          <th>Horário de Início</th>
          <th>Horário de Término</th>
          <th>Iniciada</th>
          <th>Qtd. Participantes</th>
          <th>Publicada</th>
          <th>Link da Gravação</th>
          <th>Duração da Gravação(min)</th>
          <th>Lista de participantes</th>
        </tr>
      </thead>
      <tbody v-for="relatorio in relatorioPrincipal" :key="relatorio.id">
        <tr>
          <td>{{relatorio.host_name}}</td>
          <td>{{relatorio.host_email}}</td>
          <td>{{relatorio.subject}}</td>
          <td>{{convertHorario(relatorio.start_time)}}</td>
          <td v-if="relatorio.real_end_time">{{convertHorario(relatorio.real_end_time)}}</td>
          <td v-else>-</td>
          <td v-if="relatorio.has_started == 0">Não</td>
          <td v-else>Sim</td>
          <td>{{relatorio.participants}}</td>
          <td v-if="relatorio.published == 0">Não</td>
          <td v-else>Sim</td>
          <td><button class="btn btn-primary" @click="criarLink(relatorio.play_url)">Acessar</button></td>
          <td>{{relatorio.record_duration}}</td>
          <td v-if="relatorio.has_started == 1"><button class="btn btn-primary" type="button" data-bs-toggle="tooltip" data-bs-placement="bottom" title="URL Participantes" @click="generateParticipants(relatorio.meetingId)">Participantes</button></td>
          <td v-else>-</td>
        </tr>
      </tbody>
    </table>
  </div>
  <!-- <participants-page :id='this.meetingId'></participants-page> -->
</div>
</template>

<script>
import axios from 'axios';
import ParticipantsPage from '../ParticipantsPage.vue';
import alert from '../../plugins/alert';

export default {
  components: {
    ParticipantsPage
  },
  data() {
    return {
      relatorioPrincipal: '',
      listMeetingsExport: '',
      meetingId: '',
      searched: false,
    }
  },

  methods: {
    criarLink(link){
      window.open(link, '_blank')
    },
    convertHorario(horario) {
      let horarioT = horario.split(' ')
      let diaT = horarioT[0].split("-").reverse().join("-").replace(/-/g, '/')
      horarioT = horarioT[1].split('+')
      horarioT = horarioT[0];

      return `${diaT}
      ${horarioT}`
    },
    clickVerParticipantes(id) {
      window.open(id);
    },
    gerarExportsList(url) {
      window.open(url, '_self')
    },
    generateParticipants(id)
    {
      this.$router.push(`/participants/${id}`)
      // this.meetingId = id;
      // this.$emit('id', id)
      // this.$router.push('/participants/meetingid');
    },
    gerarRelatorio() {
      let dataInicial = document.getElementById('dateInicial').value
      let dataFinal =  document.getElementById('dateFinal').value
      this.$loading(true);

      this.listMeetingsExport = `${process.env.VUE_APP_ROOT_API}/zoom/reports/exports/list/${dataInicial}/${dataFinal}`;

      axios.get(`${process.env.VUE_APP_ROOT_API}/zoom/reports/list/${dataInicial}/${dataFinal}`, {
      }).then((resposta) => {
        this.searched = true;
        this.relatorioPrincipal = resposta.data.data;
        alert.fireAlert('Relatórios gerados com sucesso!', {
          classes: 'alert-success bi bi-check-circle',
          styles:
            'background-color: #d4edda; border-color: #c3e6cb; color: #155724;',
        });
      }).catch((error) => {
        console.error(error);
        console.log(`ERROR USER ID: ${id}`);
        console.log(`ERROR COURSE ID: ${course_idS}`);
        alert.fireAlert(
            `Não foi possível gerar relatórios.`,
            {
              classes: 'alert-danger bi bi-exclamation-circle',
              styles:
                'background-color: #f8d7da; border-color: #F5C6CB; color: #721C24;',
              tag: 'httpAlert',
            }
          );
      }).finally(() => {
        this.$loading(false);
      });

    }
  },
}
</script>

<style scoped>
.reports {
  background-color: lightblue;
}

table {
  border-collapse: collapse;
  /* border-radius: 15px; */
  border-style: hidden /* hide standard table (collapsed) border */;
  box-shadow: 0 0 0 1px #666 /* this draws the table border  */;
  background-color: #fff;
  height: 40%;
  width: auto;
}
table th {
  padding: 0px 10px;
  text-align: center;
  border: 1px solid #ccc;
}
table td {
  /* overflow:auto; */
  padding: 0px 10px;
  text-align: center;
  border: 1px solid #ccc;
  word-break: break-word;
}

table td:last-child,  table td:nth-child(4), table td:nth-child(5), table td:nth-child(9){
  word-break: normal;
}

.table-titles {
  color: #FFF;
  background-color: var(--azul-tres);
}
</style>
