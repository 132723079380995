<template>
  <div class="table-reports justify-content-center d-flex flex-column mt-4 container w-100">
    <div class="d-flex flex-row mb-2 mt-2 justify-content-between">
      <button class="btn btn-primary" @click="backRoute">Voltar</button>
      <h3 class="text-center justify-content-center d-flex">Relatório dos participantes</h3>
      <button class="btn btn-primary" @click="gerarExport(urlExportMeeting)">Exportar</button>
    </div>
    <table class="mb-5">
      <thead class="table-titles">
        <tr>
          <th>User ID</th>
          <th>Meeting ID</th>
          <th>Tempo de entrada</th>
          <th>E-mail</th>
          <th>Duração da Gravação</th>
        </tr>
      </thead>
      <tbody v-for="participante in participantes" :key="participante.id">
        <tr>
          <td>{{participante.user_id}}</td>
          <td>{{participante.meeting_id}}</td>
          <td v-if="participante.join_time">{{convertHorario(participante.join_time)}}</td>
          <td>{{participante.user_email}}</td>
          <td>{{participante.duration}}</td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
import axios from 'axios';

export default {
  name: 'Painel',
  urlExportMeeting: '',
  data() {
    return {
      participantes: '',
      meetingId: '',
      
    };
  },
  beforeCreate() {
    this.meetingId = this.$route.params.meetingId
    console.log('ID DO EXPORT: ' + this.meetingId)
    this.urlExportMeeting = `${process.env.VUE_APP_ROOT_API}/zoom/reports/exports/meetings/${this.meetingId}/participants`;
    this.$loading(true);
    axios.get(`${process.env.VUE_APP_ROOT_API}/zoom/reports/meetings/${this.meetingId}/participants`, {
      }).then((res) => {
        this.participantes = res.data.data.participants;
        console.log(this.participantes, 'get do participants');
      }).catch((err) => {
        console.log('deu erro bro',err)
      }).finally (() => {
        this.$loading(false);
      });
  },
  methods: {
    convertHorario(horario) {
      let horarioT = horario.split('T')
      let diaT = horarioT[0].split("-").reverse().join("-").replace(/-/g, '/')
      horarioT = horarioT[1].split('.')
      horarioT = horarioT[0];

      return `${diaT}
      ${horarioT}`
    },
    backRoute() {
      this.$router.go(-1)
    },
    gerarExport(url) {
      window.open(url, '_self')
    },
  },
};
</script>

<style scoped>

table {
  border-collapse: collapse;
  /* border-radius: 15px; */
  border-style: hidden /* hide standard table (collapsed) border */;
  box-shadow: 0 0 0 1px #666 /* this draws the table border  */;
  background-color: #fff;
  height: 40%;
  width: auto;
}
table th {
  padding: 0px 10px;
  text-align: center;
  border: 1px solid #ccc;
}

table td {
  padding: 0px 10px;
  text-align: center;
  border: 1px solid #ccc;
}

.table-titles {
  color: #FFF;
  background-color: var(--azul-tres);
}
</style>