<template>
<modal
  class="text-center"
  id="modal-share-meeting"
  :loading="loading"
  ref="modal"
  size="md"
  title="COMPARTILHAMENTO DE GRAVAÇÕES"
  @process='shareMeeting'
  process-button-label="Sim"
  processButtonClass="btn-primary"
  close-button-label="Não"
  >
  <div class="text-start container">
    <span class="share-record-text"></span>
  </div>
</modal>
</template>

<script>
import Modal from '../../ui/Modal.vue';
import axios from 'axios';
import alert from '../../plugins/alert';

export default {
  name: 'ModalError',
  components: {
    Modal,
  },
  props: {
    meetingId: String,
  },
  data() {
    return {
      loading: false,
      buttonColor: true,
    };
  },
  methods: {
    shareMeeting() {
      this.$loading(true);
      axios.post(`${process.env.VUE_APP_ROOT_API}/blackboard/courses/publish`, {
        meetingId: this.meetingId,
      }).then((resposta) => {
        this.$refs.modal.close();
        alert.fireAlert('Aula compartilhada com sucesso!', {
            classes: 'alert-success bi bi-check-circle',
            styles:
              'background-color: #d4edda; border-color: #c3e6cb; color: #155724;',
          });
        this.$emit('share');
      }).catch((error) => {
        alert.fireAlert(`Não foi possível compartilhar aula.`, {
              classes: 'alert-danger bi bi-exclamation-circle',
              styles:
                'background-color: #f8d7da; border-color: #F5C6CB; color: #721C24;',
              tag: 'httpAlert',
            });
        console.error(error);
      }).finally(() => {
        this.$loading(false);
      });
    }
  },
};
</script>

<style scoped>

.modal-header {
  background-color: red;
}
.close {
  display: none;
}
.modal-title {
  font-weight: bold;
  color: #FFF;
  margin-left: 2rem;
}

::v-deep .btn-primary {
  background-color: #008000 !important;
}

</style>