<template>
<div v-if="isSelected">
  <slot></slot>
</div>
</template>

<script>
import TabNav from './TabNav.vue';

export default {
  components: {
    TabNav,
  },
  props: {
    isSelected: {
      type: Boolean,
    },
  },
};
</script>
