<template>
<div class="teste-pagina">
  <Index class="container d-flex justify-content-center"></Index>
</div>
</template>

<script>
import Index from './configuration/Index.vue';

export default {
  name: 'Painel',
  components: {
    Index,
  },
};

</script>
